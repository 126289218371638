var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: "mdi-contacts", title: "Contatos do site" } },
    [
      _c("EmcTableItems", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.text, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-modal-form", {
        attrs: {
          title: _vm.titleForm,
          icon: "mdi-contacts",
          show: _vm.showForm
        },
        on: {
          "update:show": function($event) {
            _vm.showForm = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.name,
                                label: "Nome*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "name", $$v)
                                },
                                expression: "model.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.email,
                                label: "Email*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.email,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "email", $$v)
                                },
                                expression: "model.email"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.phone,
                                label: "Telefone*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "phone", $$v)
                                },
                                expression: "model.phone"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.company,
                                label: "Escola"
                              },
                              model: {
                                value: _vm.model.company,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "company", $$v)
                                },
                                expression: "model.company"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: "",
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.job_title,
                                label: "Cargo"
                              },
                              model: {
                                value: _vm.model.job_title,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "job_title", $$v)
                                },
                                expression: "model.job_title"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: "",
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.subject,
                                label: "Assunto*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.subject,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "subject", $$v)
                                },
                                expression: "model.subject"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("SysSelectStatusContact", {
                              attrs: {
                                model: _vm.model.status_contact_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.status_contact_id,
                                label: "Status*",
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "status_contact_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                label: "Descrição",
                                filled: "",
                                disabled: ""
                              },
                              model: {
                                value: _vm.model.description,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "description", $$v)
                                },
                                expression: "model.description"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("v-textarea", {
                              attrs: { label: "Comentário interno:" },
                              model: {
                                value: _vm.model.comments,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "comments", $$v)
                                },
                                expression: "model.comments"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("small", [_vm._v("*Campos obrigatórios")]),
                _vm._v(" "),
                _c("br"),
                _c(
                  "small",
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-clock-outline")
                    ]),
                    _vm._v(" criado " + _vm._s(_vm.model.created_at))
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.updating,
                      disabled: _vm.updating
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.save()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.model.id ? "Atualizar" : "Cadastrar") + " "
                    ),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-content-save")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }